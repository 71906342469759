<template>
  <div>
    <b-modal id="arrangeModal" title="Sıralama">
      <p class="my-4">Rapordaki başlıkların sıralaması</p>
      <div>
        <InputTile label="Rapor Başlığı" v-model="reportTitle" type="text" />
        <InputTile label="Yatay" custom>
          <b-checkbox v-model="pageOrientation" />
        </InputTile>
        <b-select
          :options="columnsToArrange"
          v-for="(column, index) in columnsToArrange"
          :key="index"
          v-model="order[index]"
        >
        </b-select>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          İptal
        </b-button>
        <b-button size="sm" variant="success" @click="save">
          {{ $route.params.id ? "Güncelle" : "Kaydet" }}
        </b-button>
        <b-button size="sm" variant="primary" @click="report">
          Raporu Al
        </b-button>
        <download-excel
        
        class="btn btn-success btn-sm"
        :fetch="getData"
        :fields="
          order.reduce((obj, e) => {
            obj[e.text] = e.value;
            return obj;
          }, {})
        "
        :worksheet="reportTitleData"
        name="filename.xls"
      >
        Excel al
      </download-excel>
      </template>
    </b-modal>
    <div v-for="(tables, index) in form" :key="index" class="accordion">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1">
          <b-button v-b-toggle="String(index)" variant="success" block>{{
            tables.title
          }}</b-button>
        </b-card-header>
        <b-collapse
          class="mt-2"
          :id="String(index)"
          visible
          accordion="my-accordion"
        >
          <table>
            <tr>
              <th>Sütun</th>
              <th>Göster</th>
              <th>Filtrele</th>
              <th>Filtre</th>
              <th>Gelişmiş</th>
            </tr>
            <tr
              v-for="(column, columnIndex) in tables.columns"
              :key="columnIndex"
            >
              <td>
                {{ column.title }}
              </td>
              <td><b-checkbox v-model="column.selected"></b-checkbox></td>
              <td>
                <b-checkbox v-model="column.filter"></b-checkbox>
              </td>
              <td v-if="column.filter">
                <b-datepicker
                  v-if="column.type == Date"
                  placeholder="Tarih Seçiniz"
                  v-model="column.advancedFilter.input"
                ></b-datepicker>
                <b-select
                  v-else-if="column.type == 'Select'"
                  :options="column.filterOptions.options"
                  v-model="column.filterOptions.value"
                ></b-select>
                <b-form-input
                  v-else
                  placeholder="Filtrele:"
                  v-model="column.advancedFilter.input"
                ></b-form-input>
              </td>
              <td v-if="column.filter">
                <b-select
                  v-if="column.type != 'Select'"
                  :options="column.advancedFilter.options"
                  v-model="column.advancedFilter.value"
                ></b-select>
              </td>
            </tr>
          </table>
        </b-collapse>
      </b-card>
    </div>
    <b-row class="mr-4" align-h="end"
      ><b-button @click="arrangeModal" variant="primary">Devam</b-button></b-row
    >
  </div>
</template>

<script>
import personel from "../../../store/constants/tables";
import { mapGetters } from "vuex";
import selections from "../../../store/constants/selections";
import PMSReportHelper from "@/store/helpers/PMSReportHelper";
import InputTile from "@/components/forms/common/InputTile.vue";
import JsonExcel from "vue-json-excel";

import {
  bloodOptions,
  reasonOptions,
  religionOptions,
  maritalOptions,
  nationalityOptions,
  genderOptions,
  educationOptions,
} from "../../../store/constants/personel";
//import participantType from "../../../store/constants/participantType";
export default {
  name: "AdvancedPersonelReport",
  components: { InputTile, "download-excel": JsonExcel },
  data() {
    return {
      columnsToArrange: [],
      order: [],
      reportTitleData: "",
      pageOrientation: false,
      form: personel.personel,
    };
  },
  computed: {
    ...mapGetters(["getClubNames", "getManagementItems", "getSelectedReport"]),

    reportTitle: {
      get() {
        if (this.getSelectedReport && this.$route.params.id) {
          return this.getSelectedReport.title;
        } else {
          return this.reportTitleData;
        }
      },
      set(val) {
        if (this.getSelectedReport && this.$route.params.id) {
          this.getSelectedReport.title = val;
        } else {
          this.reportTitleData = val;
        }
      },
    },
  },

  async created() {
    await this.$store.dispatch("initManagement");
    await this.$store.dispatch("getClubNames");
    this.form.branch.columns[
      this.form.branch.columns.findIndex((e) => e.columnName == "clubId")
    ].filterOptions.options = this.getClubNames.map((e) => {
      return { value: e.id, text: e.name };
    });
    this.form.branch.columns[
      this.form.branch.columns.findIndex((e) => e.columnName == "clubId")
    ].filterOptions.value = this.getClubNames.map((e) => {
      return { value: e.id, text: e.name };
    })[0]
      ? this.getClubNames.map((e) => {
          return { value: e.id, text: e.name };
        })[0].value
      : null;
    this.form.branch.columns[
      this.form.branch.columns.findIndex((e) => e.columnName == "category")
    ].filterOptions.options = this.getManagementItems
      .filter((e) => {
        return e.category == selections.CATEGORY;
      })
      .map((e) => {
        return {
          text: e.title,
          value: e.id,
        };
      });
    this.form.branch.columns[
      this.form.branch.columns.findIndex((e) => e.columnName == "category")
    ].filterOptions.value = this.getManagementItems
      .filter((e) => {
        return e.category == selections.CATEGORY;
      })
      .map((e) => {
        return {
          text: e.title,
          value: e.id,
        };
      })[0]
      ? this.getManagementItems
          .filter((e) => {
            return e.category == selections.CATEGORY;
          })
          .map((e) => {
            return {
              text: e.title,
              value: e.id,
            };
          })[0].value
      : null;
    if (this.$route.params.id) {
      await this.$store.dispatch("initSelectedReport", this.$route.params.id);
      this.form = JSON.parse(this.getSelectedReport.json);
      const personel = PMSReportHelper.getSelectedPersonelColumns(
        true,
        this.form
      );
      const fields = [];
      fields.push(...personel.personel.filter((e) => e.selected));
      fields.push(...personel.branch.filter((e) => e.selected));
      fields.push(...personel.education.filter((e) => e.selected));
      fields.push(...personel.contact.filter((e) => e.selected));
      fields.push(...personel.system.filter((e) => e.selected));
      this.columnsToArrange = fields.map((e) => {
        return {
          text: e.title,
          value: { text: e.title, value: e.columnName },
        };
      });
      this.order = this.getSelectedReport.order
        .split(",")
        .filter((e) => e)
        .map(
          (e) => this.columnsToArrange.find((c) => c.value.value == e).value
        );
      this.pageOrientation = this.getSelectedReport.orientation;
    }
  },

  methods: {
    arrangeModal() {
      const personel = PMSReportHelper.getSelectedPersonelColumns(
        true,
        this.form
      );
      const fields = [];
      fields.push(...personel.personel.filter((e) => e.selected));
      fields.push(...personel.branch.filter((e) => e.selected));
      fields.push(...personel.education.filter((e) => e.selected));
      fields.push(...personel.contact.filter((e) => e.selected));
      fields.push(...personel.system.filter((e) => e.selected));
      this.columnsToArrange = fields.map((e) => {
        return {
          text: e.title,
          value: { text: e.title, value: e.columnName },
        };
      });
      this.order = this.columnsToArrange.map((e, i) =>
        this.order[i] ? this.order[i] : e.value
      );
      this.$bvModal.show("arrangeModal");
    },
    save() {
      this.$emit("save", {
        title: this.reportTitle,
        json: JSON.stringify(this.form),
        order: this.order.map((e) => e.value).join(","),
        orientation: this.pageOrientation,
        type: "personel",
      });
      this.$bvModal.hide("arrangeModal");
    },
    report() {
      PMSReportHelper.getPdfItemsFromServer(
        "/advancedPersonelReport",
        PMSReportHelper.getSelectedPersonelColumns(false, this.form)
      ).then((res) => {
        const result = this.localizePersonelReport(res);
        PMSReportHelper.generateHTML(
          this.reportTitle,
          this.order.map((e) => e.text),
          result.map((e) =>
            this.order.map((o) => (e[o.value] ? e[o.value] : ""))
          ),
          this.pageOrientation
        );
      });
    },
    localizePersonelReport(res) {
      const result = res;
      result.forEach((el) => {
        el.nationality
          ? (el.nationality = nationalityOptions.find(
              (e) => e.value == el.nationality
            ).text)
          : "";
        el.maritalStatus
          ? (el.maritalStatus = maritalOptions.find(
              (e) => e.value == el.maritalStatus
            ).text)
          : "";
        el.religion
          ? (el.religion = religionOptions.find(
              (e) => e.value == el.religion
            ).text)
          : "";
        el.bloodGrup
          ? (el.bloodGrup = bloodOptions.find(
              (e) => e.value == el.bloodGrup
            ).text)
          : "";
        el.reasonForGiven
          ? (el.lreasonForGiven = reasonOptions.find(
              (e) => e.value == el.reasonForGiven
            ).text)
          : "";
        el.gender
          ? (el.gender = genderOptions.find((e) => e.value == el.gender).text)
          : "";
        el.sportState
          ? (el.sportState = [
              { text: "Faal Olmayan", value: "stopped" },
              { text: "Faal", value: "active" },
            ].find((e) => e.value == el.sportState).text)
          : "";
        el.sportRefereeState
          ? (el.sportRefereeState = [
              { text: "Faal Olmayan", value: "stopped" },
              { text: "Faal", value: "active" },
            ].find((e) => e.value == el.sportRefereeState).text)
          : "";
        el.trainerState
          ? (el.trainerState = [
              { text: "Faal Olmayan", value: "stopped" },
              { text: "Faal", value: "active" },
            ].find((e) => e.value == el.trainerState).text)
          : "";
        el.provincialRepresentative
          ? (el.provincialRepresentative = [
              { text: "Faal Olmayan", value: "stopped" },
              { text: "Faal", value: "active" },
            ].find((e) => e.value == el.provincialRepresentative).text)
          : "";
        el.assemblyMember
          ? (el.assemblyMember = [
              { text: "Faal Olmayan", value: "stopped" },
              { text: "Faal", value: "active" },
            ].find((e) => e.value == el.assemblyMember).text)
          : "";
        el.personel
          ? (el.personel = [
              { text: "Faal Olmayan", value: "stopped" },
              { text: "Faal", value: "active" },
            ].find((e) => e.value == el.personel).text)
          : "";
        el.manager
          ? (el.manager = [
              { text: "Faal Olmayan", value: "stopped" },
              { text: "Faal", value: "active" },
            ].find((e) => e.value == el.manager).text)
          : "";
        el.educationStatus
          ? (el.educationStatus = educationOptions.find(
              (e) => e.value == e.educationStatus
            ).text)
          : "";
        el.dateForGiven
          ? (el.dateForGiven = this.dateFormatter(el.dateForGiven))
          : "";
        el.birthDate ? (el.birthDate = this.dateFormatter(el.birthDate)) : "";
        el.stillWaterCandidateRefereePromotionDate
          ? (el.stillWaterCandidateRefereePromotionDate = this.dateFormatter(
              el.stillWaterCandidateRefereePromotionDate
            ))
          : "";
        el.stillWaterDistrictRefereePromotionDate
          ? (el.stillWaterDistrictRefereePromotionDate = this.dateFormatter(
              el.stillWaterDistrictRefereePromotionDate
            ))
          : "";
        el.stillWaterNationalRefereePromotionDate
          ? (el.stillWaterNationalRefereePromotionDate = this.dateFormatter(
              el.stillWaterNationalRefereePromotionDate
            ))
          : "";
        el.stillWaterInternationalRefereePromotionDate
          ? (el.stillWaterInternationalRefereePromotionDate =
              this.dateFormatter(
                el.stillWaterInternationalRefereePromotionDate
              ))
          : "";
        el.runningWaterCandidateRefereePromotionDate
          ? (el.runningWaterCandidateRefereePromotionDate = this.dateFormatter(
              el.runningWaterCandidateRefereePromotionDate
            ))
          : "";
        el.runningWaterDistrictRefereePromotionDate
          ? (el.runningWaterDistrictRefereePromotionDate = this.dateFormatter(
              el.runningWaterDistrictRefereePromotionDate
            ))
          : "";
        el.runningWaterNationalRefereePromotionDate
          ? (el.runningWaterNationalRefereePromotionDate = this.dateFormatter(
              el.runningWaterNationalRefereePromotionDate
            ))
          : "";
        el.runningWaterInternationalRefereePromotionDate
          ? (el.runningWaterInternationalRefereePromotionDate =
              this.dateFormatter(
                el.runningWaterInternationalRefereePromotionDate
              ))
          : "";
        el.stillWaterAssistantTrainerPromotionDate
          ? (el.stillWaterAssistantTrainerPromotionDate = this.dateFormatter(
              el.stillWaterAssistantTrainerPromotionDate
            ))
          : "";
        el.stillWaterTrainerPromotionDate
          ? (el.stillWaterTrainerPromotionDate = this.dateFormatter(
              el.stillWaterTrainerPromotionDate
            ))
          : "";
        el.stillWaterSeniorTrainerPromotionDate
          ? (el.stillWaterSeniorTrainerPromotionDate = this.dateFormatter(
              el.stillWaterSeniorTrainerPromotionDate
            ))
          : "";
        el.stillWaterHeadTrainerPromotionDate
          ? (el.stillWaterHeadTrainerPromotionDate = this.dateFormatter(
              el.stillWaterHeadTrainerPromotionDate
            ))
          : "";
        el.stillWaterCoachPromotionDate
          ? (el.stillWaterCoachPromotionDate = this.dateFormatter(
              el.stillWaterCoachPromotionDate
            ))
          : "";
        el.runningWaterTrainerPromotionDate
          ? (el.runningWaterTrainerPromotionDate = this.dateFormatter(
              el.runningWaterTrainerPromotionDate
            ))
          : "";
        el.runningWaterAssistantTrainerPromotionDate
          ? (el.runningWaterAssistantTrainerPromotionDate = this.dateFormatter(
              el.runningWaterAssistantTrainerPromotionDate
            ))
          : "";
        el.runningWaterSeniorTrainerPromotionDate
          ? (el.runningWaterSeniorTrainerPromotionDate = this.dateFormatter(
              el.runningWaterSeniorTrainerPromotionDate
            ))
          : "";
        el.runningWaterHeadTrainerPromotionDate
          ? (el.runningWaterHeadTrainerPromotionDate = this.dateFormatter(
              el.runningWaterHeadTrainerPromotionDate
            ))
          : "";
        el.runningWaterCoachPromotionDate
          ? (el.runningWaterCoachPromotionDate = this.dateFormatter(
              el.runningWaterCoachPromotionDate
            ))
          : "";
      });
      return result;
    },
    dateFormatter(date) {
      return date
        ? date.includes("T")
          ? date.split("T")[1].split(".")[0].replace(":", ".")
          : date.split("-").reverse().join(".")
        : "";
    },
    async getData() {
      return this.localizePersonelReport(await PMSReportHelper.getPdfItemsFromServer(
        "/advancedPersonelReport",
        PMSReportHelper.getSelectedPersonelColumns(false, this.form)
      ));
    },
  },
};
</script>

<style>
th,
td {
  padding: 15px;
  text-align: left;
}
</style>