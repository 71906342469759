<template>
  <b-container class="mt-4">
    <b-card header-tag="header" header-class="header">
      <template #header>
        <label v-text="'Gelişmiş Raporlama'" class="mr-2 text-white"></label>
        <b-button variant="primary" class="ml-2" to="/advancedReports"
          >Geri</b-button
        >
      </template>
      <b-alert show>
        <b>1.</b> Raporda görünmesini istediğiniz alanların
        <b>Göster</b> kutucuğunu seçiniz.<br />
        <b>2.</b> Raporda verileri filtrelemek için <b>Filtrele</b> kutucuğunu
        seçip, yanda açılan bölümlerden istediğiniz filtreyi seçiniz.<br />
        <b>3.</b> İstenilen özellikler seçildikten sonra sağ aşağıda bulunan
        <b>Devam</b> butonuna tıklayınız. <br />
        <b>4.</b> Açılan pencereden, raporun başlığını, yatay olup olmayacağını
        ve gösterilecek alanların sırasını ayarlayabilirsiniz.<br />
        <b>5.</b> Son olarak <b>Raporu Al</b> butonu ile raporu yazdırabilir,
        <b>Kaydet</b> butonu ile, raporu daha sonra da kullanmak için
        kaydedebilirsiniz.
      </b-alert>
      <b-alert show variant="warning">
        <b>Uyarı!</b> Göster kutucuğunu fazla seçerseniz tabloya sığmayabilir.
      </b-alert>
      <b-form-select
        v-model="form.table"
        :options="formOptions.tables"
        v-if="!$route.params.id"
        required
      >
      </b-form-select>
      <hr />
      <AdvancedPersonelReport
        @save="save"
        v-if="
          form.table == 'personel' ||
          (getSelectedReport &&
            getSelectedReport.type == 'personel' &&
            $route.params.id)
        "
      ></AdvancedPersonelReport>
      <AdvancedClubReport
        @save="save"
        v-else-if="
          form.table == 'club' ||
          (getSelectedReport &&
            getSelectedReport.type == 'club' &&
            $route.params.id)
        "
      ></AdvancedClubReport>
      <AdvancedCompetitionReport
        @save="save"
        v-else-if="
          form.table == 'competition' ||
          (getSelectedReport &&
            getSelectedReport.type == 'competition' &&
            $route.params.id)
        "
      ></AdvancedCompetitionReport>
      <AdvancedCompetitionGroupReport
        @save="save"
        v-else-if="
          form.table == 'competitionGroup' ||
          (getSelectedReport &&
            getSelectedReport.type == 'competitionGroup' &&
            $route.params.id)
        "
      ></AdvancedCompetitionGroupReport>
      <AdvancedMatchReport
        @save="save"
        v-else-if="
          form.table == 'match' ||
          (getSelectedReport &&
            getSelectedReport.type == 'match' &&
            $route.params.id)
        "
      ></AdvancedMatchReport>
    </b-card>
  </b-container>
</template>

<script>
import reportHelper from "@/store/helpers/PMSReportHelper";
import reports from "@/store/constants/reports.js";
import AdvancedClubReport from "./AdvancedClubReport.vue";
import AdvancedPersonelReport from "./AdvancedPersonelReport.vue";
import AdvancedCompetitionReport from "./AdvancedCompetitionReport.vue";
import AdvancedCompetitionGroupReport from "./AdvancedCompetitionGroupReport.vue";
import AdvancedMatchReport from "./AdvencedMatchReport.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    AdvancedPersonelReport,
    AdvancedClubReport,
    AdvancedCompetitionReport,
    AdvancedCompetitionGroupReport,
    AdvancedMatchReport,
  },
  computed: {
    ...mapGetters(["getSelectedReport"]),
  },
  data() {
    return {
      reports,
      formOptions: {
        tables: [
          { text: "Tablo", value: "" },
          { text: "Personel", value: "personel" },
          { text: "Kulüp", value: "club" },
          { text: "Yarışmalar", value: "competition" },
          { text: "Yarışma Grupları", value: "competitionGroup" },
          { text: "Karşılaşmalar", value: "match" },
        ],
      },
      form: {
        table: "",
      },
      editId: null,
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.editId = this.$route.params.id;
      this.$store.commit("updateSelectedReport", null);
      await this.$store.dispatch("initSelectedReport", this.editId);
    }
  },
  methods: {
    async downloadPdf() {
      reportHelper.generateHTML(
        reports.club.title,
        reports.club.fields,
        await reportHelper.getPdfItemsFromServer(
          reports.club.endpoint,
          this.form
        )
      );
    },
    save(report) {
      if (this.$route.params.id) {
        this.$store.dispatch("editReportItem", {
          id: this.$route.params.id,
          title: report.title,
          json: report.json,
          orientation: report.orientation,
          order: report.order,
          type: report.type,
        });
        this.$router.back();
      } else {
        this.$store.dispatch("saveReport", {
          title: report.title,
          json: report.json,
          orientation: report.orientation,
          order: report.order,
          type: report.type,
        });
        this.$router.back();
      }
    },
  },
};
</script>

<style>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>