<template>
  <div>
    <b-modal id="arrangeModal" title="Sıralama">
      <p class="my-4">Rapordaki başlıkların sıralaması</p>
      <div>
        <InputTile label="Rapor Başlığı" v-model="reportTitle" type="text" />
        <InputTile label="Yatay" custom>
          <b-checkbox v-model="pageOrientation" />
        </InputTile>
        <b-select
          :options="columnsToArrange"
          v-for="(column, index) in columnsToArrange"
          :key="index"
          v-model="order[index]"
        >
        </b-select>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          İptal
        </b-button>
        <b-button size="sm" variant="warning" @click="save">
          {{ $route.params.id ? "Güncelle" : "Kaydet" }}
        </b-button>
        <b-button size="sm" variant="primary" @click="report">
          Raporu Al
        </b-button>
        <download-excel
        
          class="btn btn-success btn-sm"
          :fetch="getData"
          :fields="
            order.reduce((obj, e) => {
              obj[e.text] = e.value;
              return obj;
            }, {})
          "
          :worksheet="reportTitleData"
          name="filename.xls"
        >
          Excel al
        </download-excel>
      </template>
    </b-modal>
    <div v-for="(tables, index) in form" :key="index" class="accordion">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1">
          <b-button v-b-toggle="String(index)" variant="success" block>{{
            tables.title
          }}</b-button>
        </b-card-header>
        <b-collapse
          class="mt-2"
          :id="String(index)"
          visible
          accordion="my-accordion"
        >
          <table>
            <tr>
              <th>Sütun</th>
              <th>Göster</th>
              <th>Filtrele</th>
              <th>Filtre</th>
              <th>Gelişmiş</th>
            </tr>
            <tr
              v-for="(column, columnIndex) in tables.columns"
              :key="columnIndex"
            >
              <td>
                {{ column.title }}
              </td>
              <td><b-checkbox v-model="column.selected"></b-checkbox></td>
              <td>
                <b-checkbox
                  v-model="column.filter"
                  v-if="!column.noFilter"
                ></b-checkbox>
              </td>
              <td v-if="column.filter">
                <b-datepicker
                  v-if="column.type == Date"
                  placeholder="Tarih Seçiniz"
                  v-model="column.advancedFilter.input"
                ></b-datepicker>
                <b-select
                  v-else-if="column.type == 'Select'"
                  :options="column.filterOptions.options"
                  v-model="column.filterOptions.value"
                ></b-select>
                <b-form-input
                  v-else
                  placeholder="Filtrele:"
                  v-model="column.advancedFilter.input"
                ></b-form-input>
              </td>
              <td v-if="column.filter">
                <b-select
                  v-if="column.type != 'Select'"
                  :options="column.advancedFilter.options"
                  v-model="column.advancedFilter.value"
                ></b-select>
              </td>
            </tr>
          </table>
        </b-collapse>
      </b-card>
    </div>
    <b-row class="mr-4" align-h="end"
      ><b-button @click="arrangeModal" variant="primary">Devam</b-button></b-row
    >
  </div>
</template>

<script>
import competitionGroups from "../../../store/constants/tables";
import { mapGetters } from "vuex";
import selections from "../../../store/constants/selections";
import PMSReportHelper from "@/store/helpers/PMSReportHelper";
import InputTile from "@/components/forms/common/InputTile.vue";
import roleOptions from "../../../store/constants/roleOptions";
import JsonExcel from "vue-json-excel";
export default {
  name: "AdvancedCompetitionGroupReport",
  components: { InputTile, "download-excel": JsonExcel },
  data() {
    return {
      columnsToArrange: [],
      order: [],
      reportTitleData: "",
      pageOrientation: false,
      form: competitionGroups.competitionGroups,
    };
  },
  computed: {
    ...mapGetters([
      "getClubNames",
      "getCompetitionNames",
      "getManagementItems",
      "getSelectedReport",
    ]),

    reportTitle: {
      get() {
        if (this.getSelectedReport && this.$route.params.id) {
          return this.getSelectedReport.title;
        } else {
          return this.reportTitleData;
        }
      },
      set(val) {
        if (this.getSelectedReport && this.$route.params.id) {
          this.getSelectedReport.title = val;
        } else {
          this.reportTitleData = val;
        }
      },
    },
  },

  async created() {
    await this.$store.dispatch("initManagement");
    await this.$store.dispatch("initCompetitionNames");
    await this.$store.dispatch("getClubNames");
    let columnIndex = this.form.competitionGroups.columns.findIndex(
      (e) => e.columnName == "clubId"
    );
    this.form.competitionGroups.columns[columnIndex].filterOptions.options =
      this.getClubNames.map((e) => {
        return { value: e.id, text: e.name };
      });
    this.form.competitionGroups.columns[columnIndex].filterOptions.value = this
      .getClubNames[0]
      ? this.getClubNames.map((e) => {
          return { value: e.id, text: e.name };
        })[0].value
      : null;
    columnIndex = this.form.competitionGroups.columns.findIndex(
      (e) => e.columnName == "competitionId"
    );
    this.form.competitionGroups.columns[columnIndex].filterOptions.options =
      this.getCompetitionNames.map((e) => {
        return { value: e.id, text: e.name };
      });
    this.form.competitionGroups.columns[columnIndex].filterOptions.value = this
      .getCompetitionNames[0]
      ? this.getCompetitionNames.map((e) => {
          return { value: e.id, text: e.name };
        })[0].value
      : null;
    columnIndex = this.form.competitionGroups.columns.findIndex(
      (e) => e.columnName == "category"
    );
    let managementOptions = this.getManagementItems.filter((e) => {
      return e.category == selections.CATEGORY;
    });
    this.form.competitionGroups.columns[columnIndex].filterOptions.options =
      managementOptions.map((e) => {
        return {
          text: e.title,
          value: e.id,
        };
      });
    this.form.competitionGroups.columns[columnIndex].filterOptions.value =
      managementOptions[0]
        ? managementOptions.map((e) => {
            return {
              text: e.title,
              value: e.id,
            };
          })[0].value
        : null;
    columnIndex = this.form.competitionGroups.columns.findIndex(
      (e) => e.columnName == "boatType"
    );
    managementOptions = this.getManagementItems.filter((e) => {
      return e.category == selections.BOAT_CLASS;
    });
    this.form.competitionGroups.columns[columnIndex].filterOptions.options =
      managementOptions.map((e) => {
        return {
          text: e.title,
          value: e.id,
        };
      });
    this.form.competitionGroups.columns[columnIndex].filterOptions.value =
      managementOptions[0]
        ? managementOptions.map((e) => {
            return {
              text: e.title,
              value: e.id,
            };
          })[0].value
        : null;
    columnIndex = this.form.competitionGroups.columns.findIndex(
      (e) => e.columnName == "distance"
    );
    managementOptions = this.getManagementItems.filter((e) => {
      return e.category == selections.DISTANCE;
    });
    this.form.competitionGroups.columns[columnIndex].filterOptions.options =
      managementOptions.map((e) => {
        return {
          text: e.title,
          value: e.id,
        };
      });
    this.form.competitionGroups.columns[columnIndex].filterOptions.value =
      managementOptions[0]
        ? managementOptions.map((e) => {
            return {
              text: e.title,
              value: e.id,
            };
          })[0].value
        : null;
    if (this.$route.params.id) {
      await this.$store.dispatch("initSelectedReport", this.$route.params.id);

      this.form = JSON.parse(this.getSelectedReport.json);
      const competitionGroups =
        PMSReportHelper.getSelectedCompetitionGroupColumns(true, this.form);
      const fields = competitionGroups.competitionGroups.filter(
        (e) => e.selected
      );
      this.columnsToArrange = fields.map((e) => {
        return {
          text: e.title,
          value: { text: e.title, value: e.columnName },
        };
      });
      this.order = this.getSelectedReport.order
        .split(",")
        .filter((e) => e)
        .map(
          (e) => this.columnsToArrange.find((c) => c.value.value == e).value
        );
      this.pageOrientation = this.getSelectedReport.orientation;
    }
  },

  methods: {
    arrangeModal() {
      const competitionGroups =
        PMSReportHelper.getSelectedCompetitionGroupColumns(true, this.form);
      const fields = [];
      fields.push(
        ...competitionGroups.competitionGroups.filter((e) => e.selected)
      );
      this.columnsToArrange = fields.map((e) => {
        return {
          text: e.title,
          value: { text: e.title, value: e.columnName },
        };
      });
      this.order = this.columnsToArrange.map((e, i) =>
        this.order[i] ? this.order[i] : e.value
      );
      this.$bvModal.show("arrangeModal");
    },
    save() {
      this.$emit("save", {
        title: this.reportTitle,
        json: JSON.stringify(this.form),
        orientation: this.pageOrientation,
        order: this.order.map((e) => e.value).join(","),
        type: "competitionGroup",
      });
      this.$bvModal.hide("arrangeModal");
    },
    report() {
      PMSReportHelper.getPdfItemsFromServer(
        "/advancedcompetitionGroupReport",
        PMSReportHelper.getSelectedCompetitionGroupColumns(false, this.form)
      ).then((res) => {
        const result = this.localizeCompetitionGroupReport(res);
        PMSReportHelper.generateHTML(
          this.reportTitle,
          this.order.map((e) => e.text),
          result
            .map((e) => this.order.map((o) => e[o.value]))
            .map((e) => (e ? e : "")),
          this.pageOrientation
        );
      });
    },
    localizeCompetitionGroupReport(res) {
      const result = res;
      result.forEach((el) => {
        if (el.formOfParticipation) {
          el.formOfParticipation = roleOptions.find(
            (e) => e.value == el.formOfParticipation
          ).text;
        }
      });
      return result;
    },
    async getData() {
      return this.localizeCompetitionGroupReport(await PMSReportHelper.getPdfItemsFromServer(
        "/advancedCompetitionGroupReport",
        PMSReportHelper.getSelectedCompetitionGroupColumns(false, this.form)
      ));
    },
  },
};
</script>

<style>
th,
td {
  padding: 15px;
  text-align: left;
}
</style>