<template>
  <div>
    <b-modal id="arrangeModal" title="Sıralama">
      <p class="my-4">Rapordaki başlıkların sıralaması</p>
      <div>
        <InputTile label="Rapor Başlığı" v-model="reportTitle" type="text" />
        <InputTile label="Yatay" custom>
          <b-checkbox v-model="pageOrientation" />
        </InputTile>
        <b-select
          :options="columnsToArrange"
          v-for="(column, index) in columnsToArrange"
          :key="index"
          v-model="order[index]"
        >
        </b-select>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          İptal
        </b-button>
        <b-button size="sm" variant="success" @click="save">
          {{ $route.params.id ? "Güncelle" : "Kaydet" }}
        </b-button>
        <b-button size="sm" variant="primary" @click="report">
          Raporu Al
        </b-button>
        <download-excel
        
        class="btn btn-success btn-sm"
        :fetch="getData"
        :fields="
          order.reduce((obj, e) => {
            obj[e.text] = e.value;
            return obj;
          }, {})
        "
        :worksheet="reportTitleData"
        name="filename.xls"
      >
        Excel al
      </download-excel>
      </template>
    </b-modal>
    <div v-for="(tables, index) in form" :key="index" class="accordion">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1">
          <b-button v-b-toggle="String(index)" variant="success" block>{{
            tables.title
          }}</b-button>
        </b-card-header>
        <b-collapse
          class="mt-2"
          :id="String(index)"
          visible
          accordion="my-accordion"
        >
          <table>
            <tr>
              <th>Sütun</th>
              <th>Göster</th>
              <th>Filtrele</th>
              <th>Filtre</th>
              <th>Gelişmiş</th>
            </tr>
            <tr
              v-for="(column, columnIndex) in tables.columns"
              :key="columnIndex"
            >
              <td>
                {{ column.title }}
              </td>
              <td><b-checkbox v-model="column.selected"></b-checkbox></td>
              <td>
                <b-checkbox v-model="column.filter"></b-checkbox>
              </td>
              <td v-if="column.filter">
                <b-datepicker
                  v-if="column.type == Date"
                  placeholder="Tarih Seçiniz"
                  v-model="column.advancedFilter.input"
                ></b-datepicker>
                <b-select
                  v-else-if="column.type == 'Select'"
                  :options="column.filterOptions.options"
                  v-model="column.filterOptions.value"
                ></b-select>
                <b-form-input
                  v-else
                  placeholder="Filtrele:"
                  v-model="column.advancedFilter.input"
                ></b-form-input>
              </td>
              <td v-if="column.filter">
                <b-select
                  v-if="column.type != 'Select'"
                  :options="column.advancedFilter.options"
                  v-model="column.advancedFilter.value"
                ></b-select>
              </td>
            </tr>
          </table>
        </b-collapse>
      </b-card>
    </div>
    <b-row class="mr-4" align-h="end"
      ><b-button @click="arrangeModal" variant="primary">Devam</b-button></b-row
    >
  </div>
</template>

<script>
import competition from "../../../store/constants/tables";
import { mapGetters } from "vuex";
import selections from "../../../store/constants/selections";
import PMSReportHelper from "@/store/helpers/PMSReportHelper";
import InputTile from "@/components/forms/common/InputTile.vue";
import JsonExcel from "vue-json-excel";
export default {
  name: "AdvancedCompetitionReport",
  components: { InputTile, "download-excel": JsonExcel },
  data() {
    return {
      columnsToArrange: [],
      order: [],
      reportTitleData: "",
      pageOrientation: false,
      form: competition.competitions,
    };
  },
  computed: {
    ...mapGetters(["getManagementItems", "getSelectedReport"]),

    reportTitle: {
      get() {
        if (this.getSelectedReport && this.$route.params.id) {
          return this.getSelectedReport.title;
        } else {
          return this.reportTitleData;
        }
      },
      set(val) {
        if (this.getSelectedReport && this.$route.params.id) {
          this.getSelectedReport.title = val;
        } else {
          this.reportTitleData = val;
        }
      },
    },
  },

  async created() {
    await this.$store.dispatch("initManagement");
    this.form.competitions.columns[
      this.form.competitions.columns.findIndex(
        (e) => e.columnName == "category"
      )
    ].filterOptions.options = this.getManagementItems
      .filter((e) => {
        return e.category == selections.CATEGORY;
      })
      .map((e) => {
        return {
          text: e.title,
          value: e.id,
        };
      });
    this.form.competitions.columns[
      this.form.competitions.columns.findIndex(
        (e) => e.columnName == "category"
      )
    ].filterOptions.value = this.getManagementItems
      .filter((e) => {
        return e.category == selections.CATEGORY;
      })
      .map((e) => {
        return {
          text: e.title,
          value: e.id,
        };
      })[0]
      ? this.getManagementItems
          .filter((e) => {
            return e.category == selections.CATEGORY;
          })
          .map((e) => {
            return {
              text: e.title,
              value: e.id,
            };
          })[0].value
      : null;
    if (this.$route.params.id) {
      await this.$store.dispatch("initSelectedReport", this.$route.params.id);

      this.form = JSON.parse(this.getSelectedReport.json);
      const competition = PMSReportHelper.getSelectedCompetitionColumns(
        true,
        this.form
      );
      const fields = [];
      fields.push(...competition.competitions.filter((e) => e.selected));
      this.columnsToArrange = fields.map((e) => {
        return {
          text: e.title,
          value: { text: e.title, value: e.columnName },
        };
      });
      this.order = this.getSelectedReport.order
        .split(",")
        .filter((e) => e)
        .map(
          (e) => this.columnsToArrange.find((c) => c.value.value == e).value
        );
      this.pageOrientation = this.getSelectedReport.orientation;
    }
  },

  methods: {
    arrangeModal() {
      const competition = PMSReportHelper.getSelectedCompetitionColumns(
        true,
        this.form
      );
      const fields = [];
      fields.push(...competition.competitions.filter((e) => e.selected));
      this.columnsToArrange = fields.map((e) => {
        return {
          text: e.title,
          value: { text: e.title, value: e.columnName },
        };
      });
      this.order = this.columnsToArrange.map((e, i) =>
        this.order[i] ? this.order[i] : e.value
      );
      this.$bvModal.show("arrangeModal");
    },
    save() {
      this.$emit("save", {
        title: this.reportTitle,
        json: JSON.stringify(this.form),
        orientation: this.pageOrientation,
        order: this.order.map((e) => e.value).join(","),
        type: "competition",
      });
      this.$bvModal.hide("arrangeModal");
    },
    report() {
      PMSReportHelper.getPdfItemsFromServer(
        "/advancedCompetitionReport",
        PMSReportHelper.getSelectedCompetitionColumns(false, this.form)
      ).then((res) => {
        const result = this.localizeCompetitionReport(res);
        PMSReportHelper.generateHTML(
          this.reportTitle,
          this.order.map((e) => e.text),
          result
            .map((e) => this.order.map((o) => e[o.value]))
            .map((e) => (e ? e : "")),
          this.pageOrientation
        );
      });
    },
    localizeCompetitionReport(res) {
      const result = res;
      result.forEach((el) => {
        el.category
          ? (el.category = this.getManagementItems
              .filter((e) => {
                return el.category.split(";").find((k) => k == e.id);
              })
              .map((e) => e.title)
              .join(", "))
          : "";
        el.location
          ? (el.location = [
              { text: "Ulusal", value: "national" },
              { text: "Uluslararası", value: "international" },
            ].find((e) => e.value == el.location).text)
          : "";
        el.startingDate
          ? (el.startingDate = this.dateFormatter(el.startingDate))
          : "";
        el.endingDate
          ? (el.endingDate = this.dateFormatter(el.endingDate))
          : "";
      });
      return result;
    },
    dateFormatter(date) {
      return date
        ? date.includes("T")
          ? date.split("T")[1].split(".")[0].replace(":", ".")
          : date.split("-").reverse().join(".")
        : "";
    },
    async getData() {
      return this.localizeCompetitionReport(await PMSReportHelper.getPdfItemsFromServer(
        "/advancedCompetitionReport",
        PMSReportHelper.getSelectedCompetitionColumns(false, this.form)
      ));
    },
  },
};
</script>

<style>
th,
td {
  padding: 15px;
  text-align: left;
}
</style>