
import { boardOptions } from "./personel.js";
import cities from "./cities.js";
import roleOptions from "./roleOptions.js";
import eliminationReason from "./eliminationReason.js";
export default {
    personel: {
        personel: {
            title: "Kimlik Bilgileri",
            columns: [
             
                {
                    title: "TC Kimlik No",
                    columnName: "ssn",
                    type: String,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
          
                {
                    title: "Soyad",
                    columnName: "surname",
                    type: String,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Ad",
                    columnName: "name",
                    type: String,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Cinsiyet",
                    columnName: "gender",
                    type: "Select",
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "male",
                        options: [
                            { value: "female", text: "Kadın" },
                            { value: "male", text: "Erkek" },
                        ]
                    }
                },
         
                {
                    title: "Doğum Tarihi",
                    columnName: "birthDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
            
            ],
        },
        branch: {
            title: "Branş Bilgileri",
            columns: [
                {
                    title: "Kulüp",
                    columnName: "clubId",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "",
                        options: [],
                    },
                },
                {
                    title: "İkinci Kulüp",
                    columnName: "secondClubId",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "",
                        options: [],
                    },
                },
                {
                    title: "Boy",
                    columnName: "size",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Kilo",
                    columnName: "weight",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Beden",
                    columnName: "bodySize",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Ayakkabı No",
                    columnName: "shoeSize",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Spor Ayakkabı No",
                    columnName: "sportsShoeSize",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Kategori",
                    columnName: "category",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "",
                        options: [],
                    },
                },
                {
                    title: "Sporcu",
                    columnName: "sportState",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "active",
                        options: [
                            {
                                text: "Faal Olmayan",
                                value: "stopped",
                            },
                            {
                                text: "Faal",
                                value: "active",
                            },
                        ],
                    },
                },
                {
                    title: "Sporcu Lisans No",
                    columnName: "sportLicenceNumber",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Sporcu Son Vize  Yılı",
                    columnName: "sportLastLicenseDate",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Hakem",
                    columnName: "sportRefereeState",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "active",
                        options: [
                            {
                                text: "Faal Olmayan",
                                value: "stopped",
                            },
                            {
                                text: "Faal",
                                value: "active",
                            },
                        ],
                    },
                },
                {
                    title: "Hakem Son Vize  Yılı",
                    columnName: "sportRefereeLastLicenseDate",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Durgunsu Aday Hakem Terfi Tarihi",
                    columnName: "stillWaterCandidateRefereePromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Durgunsu Bölge Hakem Terfi Tarihi",
                    columnName: "stillWaterDistrictRefereePromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Durgunsu Milli Hakem Terfi Tarihi",
                    columnName: "stillWaterNationalRefereePromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Durgunsu Uluslararası Hakem Terfi Tarihi",
                    columnName: "stillWaterInternationalRefereePromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Akarsu Aday Hakem Terfi Tarihi",
                    columnName: "runningWaterCandidateRefereePromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Akarsu Bölge Hakem Terfi Tarihi",
                    columnName: "runningWaterDistrictRefereePromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Akarsu Milli Hakem Terfi Tarihi",
                    columnName: "runningWaterNationalRefereePromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Akarsu Uluslararası Hakem Terfi Tarihi",
                    columnName: "runningWaterInternationalRefereePromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Antrenör",
                    columnName: "trainerState",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "active",
                        options: [
                            {
                                text: "Faal Olmayan",
                                value: "stopped",
                            },
                            {
                                text: "Faal",
                                value: "active",
                            },
                        ],
                    },
                },
                {
                    title: "Antrenör Son Vize Yılı",
                    columnName: "trainerLastLicenseDate",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [

                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Durgunsu Yardımcı Antrenör Terfi Tarihi",
                    columnName: "stillWaterAssistantTrainerPromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [

                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },

                {
                    title: "Durgunsu Antrenör Terfi Tarihi",
                    columnName: "stillWaterTrainerPromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [

                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Durgunsu Kıdemli Antrenör Terfi Tarihi",
                    columnName: "stillWaterSeniorTrainerPromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [

                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Durgunsu Baş Antrenör Terfi Tarihi",
                    columnName: "stillWaterHeadTrainerPromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [

                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Durgunsu Teknik Direktör Terfi Tarihi",
                    columnName: "stillWaterCoachPromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [

                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Akarsu Yardımcı Antrenör Terfi Tarihi",
                    columnName: "runningWaterTrainerPromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [

                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Akarsu Antrenör Terfi Tarihi",
                    columnName: "runningWaterAssistantTrainerPromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [

                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Akarsu Kıdemli Antrenör Terfi Tarihi",
                    columnName: "runningWaterSeniorTrainerPromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [

                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Akarsu Baş Antrenör Terfi Tarihi",
                    columnName: "runningWaterHeadTrainerPromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Akarsu Teknik Direktör Terfi Tarihi",
                    columnName: "runningWaterCoachPromotionDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "İl Temsilcisi",
                    columnName: "provincialRepresentative",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "active",
                        options: [
                            {
                                text: "Faal Olmayan",
                                value: "stopped",
                            },
                            {
                                text: "Faal",
                                value: "active",
                            },
                        ],
                    },
                },
                {
                    title: "Kurul Üyesi",
                    columnName: "assemblyMember",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "active",
                        options: [
                            {
                                text: "Faal Olmayan",
                                value: "stopped",
                            },
                            {
                                text: "Faal",
                                value: "active",
                            },
                        ],
                    },
                },
                {
                    title: "Kurul",
                    columnName: "assembly",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "",
                        options: boardOptions,
                    },
                },
                {
                    title: "Personel",
                    columnName: "personel",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "active",
                        options: [
                            {
                                text: "Faal Olmayan",
                                value: "stopped",
                            },
                            {
                                text: "Faal",
                                value: "active",
                            },
                        ],
                    },
                },
                {
                    title: "Personel Görev",
                    columnName: "personelDuty",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Yönetici",
                    columnName: "manager",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "active",
                        options: [
                            {
                                text: "Faal Olmayan",
                                value: "stopped",
                            },
                            {
                                text: "Faal",
                                value: "active",
                            },
                        ],
                    },
                },
                {
                    title: "Yönetici Görev",
                    columnName: "managerDuty",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Açıklama",
                    columnName: "description",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },

            ]
        },
        education: {
            title: "Eğitim Bilgileri",
            columns: [
                {
                    title: "Öğrenim Durumu",
                    columnName: "educationStatus",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "",
                        options: [
                            { value: "", text: "Seçin" },
                            {
                                text: "İlköğretim",
                                value: "primary",
                            },
                            {
                                text: "Lise",
                                value: "high",
                            },
                            {
                                text: "Üniversite",
                                value: "college",
                            },
                        ],
                    },
                },
                {
                    title: "Okul Adı/Bölüm/No",
                    columnName: "schoolDepartmentNo",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "En Son öğrenim Gördüğü Okul",
                    columnName: "lastSchoolAttended",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Yabancı Dil",
                    columnName: "foreignLanguage",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
            ]

        },
        system: {
            title: "Sistem Bilgileri",
            columns: [
                {
                    title: "Şifre",
                    columnName: "password",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },]
        },
        contact: {
            title: "İletişim Bilgileri",
            columns: [
                {
                    title: "İş Telefon No",
                    columnName: "workPhoneNumber",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "İş Adres",
                    columnName: "workAddress",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Ev Telefon No",
                    columnName: "homePhoneNumber",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Ev Adres",
                    columnName: "homeAdress",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Yaşadığı İl",
                    columnName: "cityWhereLocated",
                    type: 'Select',
                    selected: true,
                    filter: false,
                    filterOptions: {
                        value: "01",
                        options: cities.filter((e) => e.value)
                    },
                },
                {
                    title: "Yaşadığı İlçe",
                    columnName: "districtWhereLocated",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Cep Telefon No",
                    columnName: "gsm",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "E Posta Adresi",
                    columnName: "email",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Web Adresi",
                    columnName: "website",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Banka Bilgileri",
                    columnName: "bankInfo",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
            ]
        }
    },
    club: {
        club: {
            title: "Kulüp Bilgileri",
            columns: [
                {
                    title: "İsim",
                    columnName: "name",
                    type: String,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Başkan",
                    columnName: "minister",
                    type: String,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Genel Sekreter",
                    columnName: "secretary",
                    type: String,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Bölge",
                    columnName: "region",
                    type: 'Select',
                    selected: true,
                    filter: false,
                    filterOptions: {
                        value: "01",
                        options: cities.filter((e) => e.value)
                    },
                },
                {
                    title: "Kayıt Numarası",
                    columnName: "registrationNumber",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },

                {
                    title: "Kapanış Tarihi",
                    columnName: "closingDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },

                {
                    title: "Kayıt Tarihi",
                    columnName: "foundationDate",
                    type: Date,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Adres",
                    columnName: "address",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Telefon No",
                    columnName: "phone",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Fax",
                    columnName: "fax",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "E Posta",
                    columnName: "email",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Web Sitesi",
                    columnName: "website",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Açıklama",
                    columnName: "description",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Akarsu Son Akredite Yılı",
                    columnName: "streamWaterLastAccreditedYear",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Durgunsu Son Akredite Yılı",
                    columnName: "stillWaterLastAccreditedYear",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Deniz Kanosu Son Akredite Yılı",
                    columnName: "seaKayakingLastAccreditedYear",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Dragonboat Son Akredite Yılı",
                    columnName: "dragonBoatLastAccreditYear",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },

            ]
        }
    },
    competitions: {
        competitions: {
            title: "Yarışmalar",
            columns: [
                {
                    title: "Lokasyon",
                    columnName: "location",
                    type: 'Select',
                    selected: true,
                    filter: false,
                    filterOptions: {
                        value: "national",
                        input: "",
                        options: [
                            {
                                text: "Ulusal",
                                value: "national",
                            },
                            {
                                text: "Uluslararası",
                                value: "international",
                            },
                        ]
                    },
                },
                {
                    title: "Kategori",
                    columnName: "category",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "",
                        options: [],
                    },
                },
                {
                    title: "İsim",
                    columnName: "name",
                    type: String,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Bölge Sayısı",
                    columnName: "countryCount",
                    type: String,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Katılımcı Sayısı",
                    columnName: "numberOfAthletesParticipating",
                    type: String,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                        ]
                    },
                },
                {
                    title: "Şehir",
                    columnName: "place",
                    type: String,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Başlangıç Tarihi",
                    columnName: "startingDate",
                    type: Date,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Bitiş Tarihi",
                    columnName: "endingDate",
                    type: Date,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Alt Yaş Sınırı",
                    columnName: "lowerAgeLimit",
                    type: String,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Üst Yaş Sınırı",
                    columnName: "upperAgeLimit",
                    type: String,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Açıklama",
                    columnName: "description",
                    type: String,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
            ]
        }

    },
    competitionGroups: {
        competitionGroups: {
            title: "Yarışma Grupları",
            columns: [
                {
                    title: "Yarışma Adı",
                    columnName: "competitionId",
                    type: 'Select',
                    selected: true,
                    filter: false,
                    filterOptions: {
                        value: "",
                        options: [],
                    },
                },
                {
                    title: "Katılımcılar",
                    columnName: "personelId1",
                    type: 'Select',
                    selected: true,
                    filter: false,
                    noFilter: true,
                    filterOptions: {
                        value: "",
                        options: [],
                    },
                },
                {
                    title: "Katılım Şekli",
                    columnName: "formOfParticipation",
                    type: 'Select',
                    selected: true,
                    filter: false,
                    filterOptions: {
                        value: "sportState",
                        options: roleOptions
                    },
                },
                {
                    title: "Kategori",
                    columnName: "category",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "",
                        options: [],
                    },
                },
                {
                    title: "Kulüp",
                    columnName: "clubId",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "",
                        options: [],
                    },
                },
                {
                    title: "Tekne Sınıfı",
                    columnName: "boatType",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "",
                        options: [],
                    },
                },
                {
                    title: "Mesafe",
                    columnName: "distance",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: "",
                        options: [],
                    },
                },
                {
                    title: "Derece",
                    columnName: "degree",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Ödül",
                    columnName: "prize",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Açıklama",
                    columnName: "description",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "like",
                        input: "",
                        options: [
                            { value: "like", text: "Değeri içeren" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
            ]
        }
    },
    matches: {
        matches: {
            title: "Karşılaşmalar",
            columns: [
                {
                    title: "Yarışma Adı",
                    columnName: "competitionId",
                    type: 'Select',
                    selected: true,
                    filter: false,
                    filterOptions: {
                        value: "",
                        options: [],
                    },
                },
                {
                    title: "Gruptaki Kişiler",
                    columnName: "groupId",
                    type: String,
                    selected: true,
                    filter: false,
                    noFilter: true,
                    advancedFilter: {
                        value: "",
                        options: []
                    },
                },
                {
                    title: "Karşılaşma Tarihi",
                    columnName: "matchDate",
                    type: Date,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Derece",
                    columnName: "rank",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Elenme Nedeni",
                    columnName: "eliminationReason",
                    type: 'Select',
                    selected: false,
                    filter: false,
                    filterOptions: {
                        value: 0,
                        input: "",
                        options: eliminationReason,
                    },
                },
                {
                    title: "Bitirme Zamanı",
                    columnName: "finishTime",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Bitirme Zamanı 2",
                    columnName: "finishTime2",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Ortalama Zaman",
                    columnName: "averageTime",
                    type: String,
                    selected: false,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
                {
                    title: "Tur",
                    columnName: "round",
                    type: String,
                    selected: true,
                    filter: false,
                    advancedFilter: {
                        value: "eq",
                        input: "",
                        options: [
                            { value: "gt", text: "Değerden büyük" },
                            { value: "lt", text: "Değerden küçük" },
                            { value: "eq", text: "Değere eşit" },
                            { value: "ne", text: "Değerden farklı" },
                        ]
                    },
                },
            ]
        }
    }
} 