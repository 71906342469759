var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"mt-4"},[_c('b-card',{attrs:{"header-tag":"header","header-class":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('label',{staticClass:"mr-2 text-white",domProps:{"textContent":_vm._s('Gelişmiş Raporlama')}}),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary","to":"/advancedReports"}},[_vm._v("Geri")])]},proxy:true}])},[_c('b-alert',{attrs:{"show":""}},[_c('b',[_vm._v("1.")]),_vm._v(" Raporda görünmesini istediğiniz alanların "),_c('b',[_vm._v("Göster")]),_vm._v(" kutucuğunu seçiniz."),_c('br'),_c('b',[_vm._v("2.")]),_vm._v(" Raporda verileri filtrelemek için "),_c('b',[_vm._v("Filtrele")]),_vm._v(" kutucuğunu seçip, yanda açılan bölümlerden istediğiniz filtreyi seçiniz."),_c('br'),_c('b',[_vm._v("3.")]),_vm._v(" İstenilen özellikler seçildikten sonra sağ aşağıda bulunan "),_c('b',[_vm._v("Devam")]),_vm._v(" butonuna tıklayınız. "),_c('br'),_c('b',[_vm._v("4.")]),_vm._v(" Açılan pencereden, raporun başlığını, yatay olup olmayacağını ve gösterilecek alanların sırasını ayarlayabilirsiniz."),_c('br'),_c('b',[_vm._v("5.")]),_vm._v(" Son olarak "),_c('b',[_vm._v("Raporu Al")]),_vm._v(" butonu ile raporu yazdırabilir, "),_c('b',[_vm._v("Kaydet")]),_vm._v(" butonu ile, raporu daha sonra da kullanmak için kaydedebilirsiniz. ")]),_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_c('b',[_vm._v("Uyarı!")]),_vm._v(" Göster kutucuğunu fazla seçerseniz tabloya sığmayabilir. ")]),(!_vm.$route.params.id)?_c('b-form-select',{attrs:{"options":_vm.formOptions.tables,"required":""},model:{value:(_vm.form.table),callback:function ($$v) {_vm.$set(_vm.form, "table", $$v)},expression:"form.table"}}):_vm._e(),_c('hr'),(
        _vm.form.table == 'personel' ||
        (_vm.getSelectedReport &&
          _vm.getSelectedReport.type == 'personel' &&
          _vm.$route.params.id)
      )?_c('AdvancedPersonelReport',{on:{"save":_vm.save}}):(
        _vm.form.table == 'club' ||
        (_vm.getSelectedReport &&
          _vm.getSelectedReport.type == 'club' &&
          _vm.$route.params.id)
      )?_c('AdvancedClubReport',{on:{"save":_vm.save}}):(
        _vm.form.table == 'competition' ||
        (_vm.getSelectedReport &&
          _vm.getSelectedReport.type == 'competition' &&
          _vm.$route.params.id)
      )?_c('AdvancedCompetitionReport',{on:{"save":_vm.save}}):(
        _vm.form.table == 'competitionGroup' ||
        (_vm.getSelectedReport &&
          _vm.getSelectedReport.type == 'competitionGroup' &&
          _vm.$route.params.id)
      )?_c('AdvancedCompetitionGroupReport',{on:{"save":_vm.save}}):(
        _vm.form.table == 'match' ||
        (_vm.getSelectedReport &&
          _vm.getSelectedReport.type == 'match' &&
          _vm.$route.params.id)
      )?_c('AdvancedMatchReport',{on:{"save":_vm.save}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }